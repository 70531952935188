import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import Slider from 'react-slick';

import { LinkModal } from "kh-common-components";

import { modalContents } from "../../constants/modal-content";

import flybuysPoints10k1pt from "../../images/flybuys/flybuys-points-plus-bonus.svg";

const promoCode = "FLYBUYS10KFB";
const promoCodeStackEndpoint = `${process.env.PROMO_CODE_STACK_API}/${promoCode}`;
    
const settings = {
    // rows: 0, // causes memory leak
    // lazyLoad: true,
    arrows: false,
    dots: true,
    infinite: false,
    initialSlide: 1,
    swipeToSlide: true,
    draggable: true,
    mobileFirst: true,
    transformEnabled: false,
    useTransform: false,

    speed: 800,
    slidesToShow: 3,

    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '100px',

    dotsClass: 'd-flex slick-dots',
    className: 'mer-media-card-carousel mer-media-card-carousel-react container-fluid-constrained mer-slick-slider--height-equal',

    responsive: [
        {
            breakpoint: 1920,
            settings: {
                slidesToShow: 3,
                centerMode: true,
                centerPadding: '100px'
            }
        },
        {
            breakpoint: 1680,
            settings: {
                slidesToShow: 3,
                centerMode: true,
                centerPadding: '100px'
            }
        },
        {
            breakpoint: 1200,
            settings: {
                slidesToShow: 3,
                centerMode: true,
                centerPadding: '1px'
            }
        },
        {
            breakpoint: 960,
            settings: {
                slidesToShow: 1,
                centerMode: true,
                centerPadding: '200px'
            }
        },
            {
            breakpoint: 860,
            settings: {
                slidesToShow: 1,
                centerMode: true,
                centerPadding: '150px'
            }
        },
        {
            breakpoint: 639, // 639 less
            settings: {
                slidesToShow: 1,
                centerMode: true,
                centerPadding: '80px'
            }
        },
        {
            breakpoint: 550, // 639 less
            settings: {
                slidesToShow: 1,
                centerMode: true,
                centerPadding: '30px'
            }
        }
    ]

};

function FlybuysMarchOfferPlans() {

    const [stackedDiscount, setStackedDiscount] = useState(null);
    
        let mediaCardRef = [];
    
        const getStackedDiscount = async () => {
    
            if (stackedDiscount !== null) {
                return;
            }
    
            try {
                const promoCodeStack = await Axios.get(promoCodeStackEndpoint);

                const stackedDiscountData = promoCodeStack.data.stackedDiscounts.filter(stackedDiscount => stackedDiscount.id === 2)[0];

                if (stackedDiscountData) {

                    let discount = stackedDiscountData.totalDiscount;
                    let discountDecimalPlace;
                    if (!Number.isNaN(discount) && !Number.isInteger(discount)) { // if discount is a number with decimal places
                        // split whole number and decimal place
                        const discountInStr = discount.toString();
                        const dotIndex = discountInStr.indexOf(".");
                        discountDecimalPlace = discountInStr.substring(dotIndex, dotIndex + 2); // only keeping 1 decimal place 
                        discount = parseInt(discount); // whole number
                    }

                    stackedDiscountData.display = {
                        discount,
                        discountDecimalPlace
                    }
                }

                setStackedDiscount(stackedDiscountData ? stackedDiscountData : null);
            } catch (error) {
                console.error('Error getting promo code stack details');
            }
        };
    
        useEffect(() => {
            getStackedDiscount();
    
            mediaCardRef.forEach(referenceItem => {
                if(referenceItem.current) {
                    referenceItem.current.parentElement.classList.add('col', 'mer-card', 'mer-card-media', 'mdc-card');
                }
            });
    
        }, [stackedDiscount]);
    
        return(
            <>
                { stackedDiscount &&
                    <section id="plans" 
                            className="mer-bg--ui-light mer-py-ju">
                        <div className="container">
    
                            <div className="row align-items-center justify-content-center">
                                <div className="col-10 col-sm-8">
                                    <div className="content text-center">
                                        <h2 className="mt-2">Your Flybuys offer</h2>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <Slider {...settings}>
    
                            {/* Only display the Monthly Smart Saver plan. */}
                            <div className="mer-card-media-react__container mer-plan-card mer-card mdc-card text-center py-4">
                                <h4 className="mer-plan-card__title">{stackedDiscount.planName}</h4>
                                <p className="mer-plan-card__description">
                                    <span className="mer-plan-card__discount">
                                        {stackedDiscount.display.discount}
                                        {stackedDiscount.display.discountDecimalPlace !== "" && <span className="mer-plan-card__discount-decimal">{stackedDiscount.display.discountDecimalPlace}</span>}
                                        <span className="mer-plan-card__discount-percent">%</span>
                                    </span>                                    
                                    <span className="mer-plan-card__discount-description">off gas usage charges {stackedDiscount.payOnTimeDiscount !== null ? "when you pay on time" : "for at least 12 months"}</span>
                                </p>
                                <div className='col-10 mx-auto py-0 mer-my-sm'>
                                    <div className="mer-panel__images d-flex align-items-center align-self-center justify-content-center">
                                        <img
                                            className="mer-panel__image"
                                            src={flybuysPoints10k1pt}
                                            alt="Flybuys points"
                                        />
                                    </div>
                                </div>
                                <div className="col-10 mx-auto py-0 py-md-1 py-lg-0 mer-my-sm">
                                    <div className="text-center mer-mt-sm">
                                        <strong className="mer-color--primary-blue">You can collect 10,000 bonus Flybuys points when you select this plan.<sup>+</sup></strong>
                                    </div>
                                </div>
                                <ul className="mer-list mer-color--primary-navy mer-border--bottom mer-border--color-gs-lighter m-0 px-2 py-3 mb-4">
                                    <li><strong>Direct debit</strong></li>
                                    <li><strong>Easy monthly instalments</strong></li>
                                    <li><strong>Email bills</strong></li>
                                    <li>No setup or exit costs</li>
                                    <li>No lock-in contracts</li>
                                </ul>
                                <div className="content mx-4 text-center">
                                    <a href={`/sign-up/promo/${promoCode}/${stackedDiscount.id}`}
                                        className="mer-button mer-button--primary mer-button--size-full-width mb-3">
                                        <span className="mer-color--gs-white">Choose plan</span>
                                    </a>
                                    <small className="ng-plan-details text-center">
                                        <LinkModal
                                            modalContents={modalContents}
                                            useModal="true"
                                            title='View plan details and non-standard terms'
                                            displayContent='View plan details and non-standard terms'
                                            modalTitle='View plan details and non-standard terms'
                                            modalContentName={`plan_details_and_terms_${stackedDiscount.id}_flybuys_march_offer`} // Copying the summer campaign terms and conditions as they it ends at the same time as the flybuys offer
                                            okayButtonLabel="Okay"
                                        />
                                    </small>
                                </div>
                            </div>
                        </Slider>
                            
                        <div className="container">
                            <div className="row align-items-center justify-content-center">
                                <div className="col-10 col-sm-8 col-md-6 col-lg-5 col-xl-4">
                                    <div className="text-center mer-mt-sm">
                                        <p className="mb-2 pb-0">
                                            <small>
                                                <LinkModal
                                                modalContents={modalContents}
                                                useModal="true"
                                                displayContent="View prices and fees for all natural gas customers"
                                                title="View prices and fees for all natural gas customers"
                                                modalTitle="View prices and fees for all natural gas customers"
                                                modalContentName="standard_prices_and_fees"
                                                okayButtonLabel="Close"
                                                />
                                            </small>
                                        </p>
                                        <p className="mt-2 pt-0">
                                            <small>
                                                <LinkModal 
                                                    modalContents={modalContents}
                                                    useModal="true"
                                                    title="Terms and conditions apply"
                                                    linkClass="mer-text--no-wrap"
                                                    displayContent={<><sup>+~*</sup>Terms and conditions apply</>}
                                                    modalTitle="Terms and conditions apply"
                                                    modalContentName='flybuys-march-offer-terms-conditions'
                                                    okayButtonLabel="Okay"
                                                />
                                            </small>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                }
            </>
);
}

export default FlybuysMarchOfferPlans;