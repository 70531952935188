import React from 'react';

import { LinkModal } from "kh-common-components";

import Header from "../components/Header";
import Footer from "../components/Footer";
import SEO from "../components/Seo";
import LayoutMain from "../components/layout/layoutMain";
import ProductHighlightNew from '../components/cards/ProductHighlightNew';
import FlybuysMarchOfferPlans from '../components/cards/FlybuysMarchOfferPlans';
import Faq from '../components/common/Faq';
import AwardsInfo from '../components/cards/AwardsInfo';
import Link from "../components/common/Link";

import flybuys10000BonusPoints from "../images/flybuys/flybuys-10000-bonus-points.png";

import faqDataOffer from "../constants/components/accordion-list/flybuys-offer";
import { modalContents } from "constants/modal-content"

import "styles/pages/flybuys-offer.css";

const promoCode = "FLYBUYS10KFB";

export default function() {

    return (
        <>
            <SEO
                title="Exclusive Flybuys member offer | Kleenheat"
                titleBespoke="true"
                description="Collect 10,000 Flybuys bonus points when you switch to Kleenheat with this exclusive Flybuys member offer."
                meta={[{name:"robots", content:'noindex'}]}
              />
        
              <Header />
              <LayoutMain>
                    <section
                        className="module-wrapper mer-bg--primary-cyan mer-theme--cyan mer-py-lg"
                        >
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="mer-panel__images col-10 col-lg-6">
                                    <img
                                        src={flybuys10000BonusPoints}
                                        className="flybuys-offer-hero mer-panel__image"
                                        alt="Flybuys 1 point per dollar"
                                    />
                                </div>
                            </div>
                            <div className="row justify-content-center">
                                <div className="col-10 col-lg-8">
                                    <div className="content text-center mer-text--size-lg">
                                        <h1>Collect 10,000 bonus points when you switch to Kleenheat.<sup>+</sup></h1>
                                        <p>
                                            Make the switch today to claim your 10,000 bonus points<sup>+</sup> and save 35% off your natural gas usage charges for the first 12 months.<sup>*</sup>
                                        </p>
                                        <p>Plus collect 1 Flybuys point per $1 spent on natural gas.<sup>~</sup></p>
                                        <div className="mer-panel__actions mer-button-group justify-content-center flex-column col-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 mx-auto">
                                            <Link
                                                text="Switch now"
                                                link={`/sign-up/promo/${promoCode}`}
                                                linkClass="mer-button mer-button--primary mr-0 mb-3"
                                            />
                        
                                            <Link
                                                text="View plan details"
                                                link="#plans"
                                                linkClass="mer-button mer-button--secondary"
                                            />
                                        </div>
                                        <p className="mer-typography--body2 mb-0">
                                            <LinkModal 
                                            modalContents={modalContents}
                                            useModal="true"
                                            title="Terms and conditions apply"
                                            linkClass="mer-text--no-wrap"
                                            displayContent={<><sup>+~*</sup>Terms and conditions apply</>}
                                            modalTitle="Terms and conditions apply"
                                            modalContentName='flybuys-march-offer-terms-conditions'
                                            okayButtonLabel="Okay"
                                            />
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                  
                    <ProductHighlightNew panelBackground="mer-bg--ui-light" panelPadding="mer-pt-lg" />

                    <FlybuysMarchOfferPlans promoCode={promoCode}/>

                    <Faq
                        panelBackground="mer-bg--ui-light"
                        panelPadding="mer-py-lg"
                        id="faq"
                        data={faqDataOffer}
                    />

                    <AwardsInfo
                        panelBackground="mer-bg--gs-grey-lighter"
                        panelPadding="mer-py-de"
                    />

                    <section
                        id="sign-up"
                        className="module-wrapper mer-bg--primary-cyan mer-theme--cyan mer-py-lg"
                        >
                        <div className="container">
                            <div className="row justify-content-center">
                            <div className="col-10 col-lg-6">
                                <div className="content text-center">
                                <div className="mer-text--size-lg">
                                    <h2>Sign up to Kleenheat today.</h2>
                                    <p>
                                        Make the switch today to claim your 10,000 bonus points<sup>+</sup> and save 35% off your natural gas usage charges for the first 12 months.<sup>*</sup>
                                    </p>
                                    <a
                                        href={`/sign-up/promo/${promoCode}`}
                                        className="mer-button mer-button--primary mb-3"
                                    >
                                        <span className="mer-color--gs-white">Switch now</span>
                                    </a>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </section>
              </LayoutMain>
              <Footer />
        </>
        
    )
}